

































































































































































































































.text-name {
  width: 150px!important;
  display: inline-block;
  line-height: 0.9;
}

.cadastro-usuario >>> .v-data-table__wrapper table {
  min-width: auto;
}
